import React from 'react';
import './CreateBlog.css';

const CreateBlog = () => {
  return (
    <div>
      <h1 className='createBlog_welcome'>Create Blog</h1>
      <img src="/images/abook1.jpg" alt="Profile Picture" className='dashboard_image' />

      {/* Add your content here */}
    </div>
  );
};

export default CreateBlog;
